import React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { theme } from '@cleverrealestate/clever-components';

const withMUI = Component => props => (
  <ThemeProvider theme={theme}>
    <Component {...props} />
  </ThemeProvider>
);

export default withMUI;
